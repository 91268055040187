import * as React from "react"
import Base from "../components/Base"

import OwnersHero from "../images/Owners Hero.png"
import OwnersBody1 from "../images/Owners Body 1.jpg"
import OwnersBody2 from "../images/Owners Body 2.jpg"
import Handyman1 from "../images/handyman1.svg"
import Handyman2 from "../images/handyman2.svg"
import icon1 from "../images/1.svg"
import icon2 from "../images/2.svg"
import icon3 from "../images/3.svg"
import icon4 from "../images/4.svg"
import icon5 from "../images/5.svg"

export default function HomeownersPage() {
  return (
    <Base data={data} />
  )
}

const data = {
  heroData: {
    image: OwnersHero,
    headline: "We make home maintenance easy",
    subtitle: "Homeplan offers homeowners simple and affordable annual maintenance plans that cover the essential jobs for your property.",
    subtitle2: "From only $27 per week."
  },
  introData: {
    tagline1: "Homeplan is your partner in property maintenance.",
    image1: OwnersBody1,
    tagline2: "A well maintained property can",
    tagline2High: "add more than 10% to it's value.",
    body: "Homeplan has partnered with the industry's leading property maintenance companies to ensure your home is always safe and secure while maintaining and increasing its value over time.",
    bulletPunch: "Our plans take care of things like:",
    bullets: ["Pest control", "Gutter cleaning", "Electrical safety check-up", "Exterior house cleaning", "Hot water system service", "General maintenance health check"],
    image2: Handyman1
  },
  howData: {
    hows: [
      { icon: icon1, text: "Sign your property up to one of our great annual maintenance plans." },
      { icon: icon2, text: "We coordinate access between you and our service partners throughout the year." },
      { icon: icon3, text: "You’ll be notified throughout the year once each job has been completed." },
      { icon: icon4, text: "Your property maintenance is taken care of giving you peace of mind." },
      { icon: icon5, text: "We can also help you coordinate other work that’s not covered in our plan." },
    ],
    image: Handyman2,
    taglineHigh: "Keep your family safe",
    tagline: "and your property maintained with Homeplan."
  },
  outroData: {
    close: "We currently only offer our maintenance plans to homeowners exclusively through our real estate agency partners across the Sydney Metro area.",
    image: OwnersBody2
  }
}